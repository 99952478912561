nav {
	width: 100%;
	background-color: navy;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 30px;
	padding: 0;
	margin: 0;
	font-weight: bolder;
	max-height: 50px;
	color: white;
}

* {
	box-sizing: border-box;
	font-family: 'Courier New', Courier, monospace;
}
nav ul {
	display: flex;
	list-style: none;
	padding: 0;
}

nav ul li {
	margin: 0;
	padding: 0;
}

.dApps {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	margin-top: 20px;
	gap: 40px;
	flex-wrap: wrap;
}

.dApps button {
	background-color: navy;
	border: none;
	font-size: 20px;
	font-weight: bolder;
	padding: 10px 20px;
	color: white;
	border-radius: 10px;
	box-shadow: 2px 2px 5px 0 black;
}

.dApps button:hover {
	box-shadow: 2px 2px 5px 0 navy;
	cursor: pointer;
	background-color: black;
}

button:active {
	box-shadow: none;
	transform: scale(0.9);
}

.main-footer {
	position: fixed;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	background-color: navy;
	color: white;
	text-align: center;
	padding: 5px;
	position: fixed;
	bottom: 0;
	width: 100%;
	gap: 5px;
	padding: 10px;
}

.main-footer a {
	color: white;
	text-decoration: none;
	font-weight: bold;
}

.main-footer p {
	margin: 0;
}

.tx-status {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	gap: 0px;
	min-width: 100%;
	background-color: rgba(151, 151, 214, 0.336);
	margin: 0;
	height: fit-content;
	margin-bottom: 100px;
	min-width: 97vw;
}

.tx-status p {
	background-color: black;
	min-width: 90%;
	min-height: 40px;
	max-height: 250px;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	max-width: 250px;
	text-wrap: wrap;
	word-break: break-all;
	box-shadow: 2px 2px 5px 0 navy;
	color: white;
}

.tx-status button {
	font-size: 17px !important;
	padding: 5px 10px !important;
	margin-bottom: 10px;
}

.tx-status table {
	width: 90%;
	margin: 20px auto;
	background-color: white;
	border-radius: 10px;
	background-color: navy;
	color: white;
	transition: all ease-in-out 0.5s;
	max-width: 90%;
	overflow: auto;

	&::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: white;
		border-radius: 10px;
	}

	&::-webkit-scrollbar-track {
		background-color: navy;
		max-width: 90%;
	}
}

.tx-status table th {
	background-color: navy;
	color: white;
	padding: 0px;
}

.tx-status table td {
	padding: 3px;
	text-wrap: wrap;
	word-break: break-all;
}

.tx-status table tr:hover {
	background-color: rgba(255, 255, 255, 0.26);
}

.user-details {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
	gap: 10px;
	max-width: 250px;
	margin-bottom: 30px;
}

.user-details h3 {
	color: navy;
	margin: 0;
	width: 100%;
	text-align: center;
}

.user-details p {
	background-color: white;
	min-width: 100%;
	min-height: 30px;
	max-height: 100px;
	border-radius: 10px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 5px 15px;
	max-width: 100%;
	text-wrap: nowrap;
	box-shadow: 2px 2px 5px 0 navy;
	overflow-x: auto;
	margin: 0;

	&::-webkit-scrollbar {
		width: 10px;
		height: 5px;
		display: none;
	}

	&::-webkit-scrollbar-thumb {
		background-color: transparent;
		border-radius: 10px;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
		max-width: 90%;
	}
}

.user-details input {
	background-color: white;
	min-width: 100%;
	min-height: 30px;
	max-height: 100px;
	border-radius: 10px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 5px 10px;
	max-width: 100%;
	text-wrap: wrap;
	box-shadow: 2px 2px 5px 0 navy;
	overflow-x: auto;
	margin: 0;
	border: none;
}
